import React, { useState } from 'react'
import styled from 'styled-components'
import MonthPicker from './month-picker'
import moment from 'moment'
import Days from './days'
import { breakpoints } from '../../helpers/media'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 420px;
  box-shadow: 1px 1px 6px -1px #00000063;
  border-radius: 5px;
  max-width: 95vw;
  @media only screen and (max-width: ${breakpoints.md}px) {
    height: 300px;
  }
`

const Calendar = ({ articles, handleClick, dateKey = 'date' }) => {
  const [date, setDate] = useState(moment().startOf('month'))

  const incMonth = () => {
    setDate(moment(date).add(1, 'months'))
  }
  const decMonth = () => {
    setDate(moment(date).add(-1, 'months'))
  }
  return (
    <Wrapper>
      <MonthPicker
        title={date.format('MMMM YYYY')}
        incMonth={incMonth}
        decMonth={decMonth}
      />
      <Days dateKey={dateKey} date={date} articles={articles} handleClick={handleClick} />
    </Wrapper>
  )
}

export default Calendar
