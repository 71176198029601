import React from 'react'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import { RichText } from 'prismic-reactjs'

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  justify-content: space-evenly;
  background-color: ${DEFAULT_COLOR};
  width: 250px;
  padding: 10px;
  color: white;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 4px 1px #0000006b;
  }
`
const Title = styled.p`
  font-size: 18px;
`
const Schedule = ({ title, link }) => {
  return (
    <Wrapper href={link} target='_blank'>
      <Icon>date_range</Icon>
      <Title>{RichText.asText(title)}</Title>
    </Wrapper>
  )
}

export default Schedule
