import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Calendar from '../../../components/calendar'
import CarCalendarColorScheme from './car-calendar-color-scheme'

const CarCalendar = ({ calendarId }) => {
  const [events, setEvents] = useState()
  useEffect(() => {
    //const CALENDAR_ID = 'c_t8151r3hvuuq5bqbg7nenh7gi0@group.calendar.google.com'
    const API_KEY = 'AIzaSyBUbI-XJ9LUgXSb--j-4FyZhriMcHFdKCA'
    const QUERY = `&timeMin=${moment()
      .startOf('year')
      .toISOString()}&singleEvents=true`
    const fullPath = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${API_KEY}${QUERY}`

    try {
      fetch(fullPath)
        .then(response => {
          return response.json()
        })
        .then(dataIn => {
          let _events = []
          dataIn.items.forEach(item => {
            let start = moment(item.start.date || item.start.dateTime)
            let end = moment(item.end.date || item.end.dateTime)
            let duration =
              item.start.date && item.end.date
                ? moment
                    .duration(
                      moment(item.end.date).diff(
                        moment(item.start.date),
                        'days'
                      ),
                      'days'
                    )
                    .asDays() - 1
                : moment
                    .duration(moment(end).diff(start, 'days'), 'days')
                    .asDays()
            for (let index = 0; index <= duration; index++) {
              let currentDate = moment(start).add(index, 'days')
              let first = index === 0
              let last = index === duration
              let currentDateStart
              let currentDateEnd
              if (first) {
                currentDateStart =
                  item.start.dateTime || moment(currentDate).startOf('day')
                currentDateEnd =
                  duration > 0
                    ? moment(currentDate).endOf('day')
                    : item.end.dateTime || moment(item.end.date).endOf('day')
              } else if (last) {
                currentDateStart = moment(currentDate).startOf('day')
                currentDateEnd =
                  item.end.dateTime || moment(item.end.date).endOf('day')
              } else {
                currentDateStart = moment(currentDate).startOf('day')
                currentDateEnd = moment(currentDate).endOf('day')
              }
              _events = [
                ..._events,
                {
                  title: item.summary,
                  date: currentDate,
                  date_start: currentDateStart,
                  date_end: currentDateEnd
                }
              ]
            }
          })
          setEvents(_events)
        })
    } catch (message) {
      console.log(message)
    }
  }, [])
  if (!events) {
    return <h1></h1>
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CarCalendarColorScheme />
      <Calendar dateKey='date' articles={events} />
    </div>
  )
}

export default CarCalendar
