import React from 'react'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { DEFAULT_COLOR } from '../../helpers/colors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15%;
  width: 100%;
  padding: 10px;
  color: ${DEFAULT_COLOR};
`

const IconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const Title = styled.h5`
  font-size: 17px;
`

const MonthPicker = ({ title, incMonth, decMonth }) => {
  return (
    <Wrapper>
      <IconWrapper onClick={() => decMonth()}>
        <Icon fontSize='small'>arrow_back_ios</Icon>
      </IconWrapper>
      <Title>{title}</Title>
      <IconWrapper onClick={() => incMonth()}>
        <Icon fontSize='small'>arrow_forward_ios</Icon>
      </IconWrapper>
    </Wrapper>
  )
}

export default MonthPicker
