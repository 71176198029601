import React, { useState } from 'react'
import styled from 'styled-components'
import Calendar from '../../../components/calendar'
import NewsDialog from './news-dialog'
import Feed from './feed'
import useTranslation from '../../../hooks/use-translation'
import Schedule from './scheme'
import { breakpoints, Media } from '../../../helpers/media'
import MobileFeed from './mobile-feed'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import moment from 'moment'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100vw;
  overflow: hidden;
  padding: 40px 10px 80px 10px;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
    padding: 40px 0px 80px 10px;
  }
`

const CalendarWrapper = styled.div``

const Title = styled.h3`
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 40px;
  letter-spacing: 0px;
  color: ${DEFAULT_COLOR};
  text-align: center;
  margin-bottom: 20px;
  ::after {
    content: '';
    width: 40%;
    margin-left: 30%;
    height: 3px;
    background-color: ${DEFAULT_COLOR};
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    display: none;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 40px;
    padding: 20px 0px 20px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`

const SchemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const StuderandeNewsDashboard = ({ articles, schemes }) => {
  const [selectedDay, setSelectedDay] = useState()
  const t = useTranslation()

  return (
    <>
      <Wrapper>
        <Media at='sm'>
          <MobileFeed
            articles={articles.filter(article => moment(article.date_end) > moment())}
            handleClick={day => setSelectedDay(day)}
          />
        </Media>
        <Media greaterThanOrEqual='md'>
          <Feed articles={articles.filter(article => moment(article.date_end) > moment())} />
        </Media>
        <Column>
          <CalendarWrapper>
            <Title>{t`Calendar`}</Title>
            <Calendar
              dateKey='date_start'
              articles={articles}
              handleClick={day => setSelectedDay(day)}
            />
          </CalendarWrapper>
          <SchemeWrapper>
            <Title>{t`Schedule`}</Title>
            {schemes.map((scheme, index) => (
              <Schedule
                key={index}
                title={scheme.scheme_title}
                link={scheme.scheme_link.url}
              />
            ))}
          </SchemeWrapper>
        </Column>
      </Wrapper>
      <NewsDialog day={selectedDay} close={() => setSelectedDay()} />
    </>
  )
}

export default StuderandeNewsDashboard
