import React from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styled from 'styled-components'
import FeedArticle from '../feed-article'
import { breakpoints } from '../../../helpers/media'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
  padding-top: 25px;
  @media only screen and (min-width: ${breakpoints.md}px) {
    padding-top: 0px;
  }

`

const NewsDialog = ({ day, close }) => {
  return (
    <Dialog
      open={!!day}
      onClose={close}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!day && (
        <Wrapper>
          {day.events ? day.events.map(article => (
            <FeedArticle article={article} />
          )) :     <FeedArticle article={day} />}
        </Wrapper>
      )}
    </Dialog>
  )
}

export default NewsDialog
