import React from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import CustomRichText from '../../../components/custom-rich-text'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
  width: 100%;
  overflow-x: hidden;
  padding: 20px;
  color: #4c4d4f;
`

const Title = styled.h2`
  margin: 25px 0px;
  text-align: center;
`

const TextWrapper = styled.div`
  text-align: center;
  margin: 10px 0px;
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16px;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`
const LinkWrapper = styled.a`
  text-decoration: none;
  margin: 5px;
  &:hover {
    color: ${DEFAULT_COLOR};
  }
`

const PdfDialog = ({ content, close }) => {
  return (
    <Dialog
      open={!!content}
      onClose={close}
      maxWidth='sm'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!content && (
        <Wrapper>
          <Title>{RichText.asText(content.title)}</Title>
          <TextWrapper>
            <CustomRichText richText={content.text} />
          </TextWrapper>
          <LinksWrapper>
            {content.links.length &&
              content.links.map(link =>
                link.pdf_link ? (
                  <LinkWrapper target='_blank' href={link.pdf_link.url}>
                    {RichText.asText(link.pdf_title)}
                  </LinkWrapper>
                ) : (
                  ''
                )
              )}
          </LinksWrapper>
        </Wrapper>
      )}
    </Dialog>
  )
}

export default PdfDialog
