import React from 'react'
import StuderandeHero from './studerande-hero'
import Layout from '../../components/layout'
import StuderandeNewsDashboard from './studerande-news-dashboard'
import StuderandeUsefulInformation from './studerande-useful-information/index'
import StuderandeSamo from './studerande-samo'
import StuderandeImportantInformation from './studerande-important-information'
import SEO from '../../components/seo'
import StuderandeNotificationDialog from './studerande-notification-dialog'
import { isSupported } from '../../helpers/subscription'

const Studerande = ({ node, informationArticles, articles, samo }) => {
  const showNotificationDialog =
    isSupported() && Notification &&
    (Notification.permission !== 'denied' ||
      Notification.permission === 'default')

  return (
    <Layout>
      <SEO title='Studerande' />
      <StuderandeHero
        title={node.hero_title}
        url={node.hero_background_image.url}
      />
      <StuderandeImportantInformation articles={informationArticles} />
      <StuderandeNewsDashboard articles={articles} schemes={node.schemes} />
      <StuderandeUsefulInformation
        usefulLinks={node.useful_links}
        bookingLinks={node.booking_links}
        bodySlices={node.body}
        bookCarImage={node.book_car_imageSharp.childImageSharp.fluid}
        bookCarLogoImage={node.book_car_logo_imageSharp.childImageSharp.fluid}
        bookCarCalendarId={node.book_car_calendar_id}
        bookCarText={node.book_car_text}
      />
      <StuderandeSamo samo={samo} />
      {showNotificationDialog && <StuderandeNotificationDialog />}
    </Layout>
  )
}

export default Studerande
