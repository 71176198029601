import React, { useState } from 'react'
import styled from 'styled-components'
import { DEFAULT_BACKGROUND, DEFAULT_COLOR } from '../../../helpers/colors'
import useTranslation from '../../../hooks/use-translation'
import { RichText } from 'prismic-reactjs'
import CarDialog from '../studerande-booking/car-dialog'
import { breakpoints } from '../../../helpers/media'
import PdfDialog from './pdf-dialog'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: ${DEFAULT_BACKGROUND};
  width: 100vw;
  overflow: hidden;
  padding: 40px 250px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
`

const Title = styled.h3`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 35px;
  text-align: flex-start;
  letter-spacing: 0px;
  color: #f7f4f4;
  text-shadow: 0px 3px 6px #0000002b;
  opacity: 1;
  margin-bottom: 40px;
  width: fit-content;
  ::after {
    content: '';
    width: 100%;
    margin-top: 3px;
    height: 3px;
    background-color: white;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 12px;
    font-size: 18px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 300px;
  margin: 30px 0px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 250px;
  }
`

/*const Divider = styled.div`
  margin: 0px 40px 0px 40px;
  align-self: flex-end;
  height: 300px;
  width: 2px;
  background-color: ${DEFAULT_COLOR};
  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: 2px;
    margin: 10px;
    height: 200px;
  }
`*/

const LinkWrapper = styled.a`
  padding: 5px;
  margin: 5px;
  text-decoration: none;
`
const LinkLabel = styled.h5`
  font-size: 25px;
  text-align: left;
  letter-spacing: 0px;
  color: #f7f4f4;
  text-shadow: 0px 3px 6px #0000002b;
  opacity: 1;
  &:hover {
    cursor: pointer;
    color: ${DEFAULT_COLOR};
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 12px;
  }
`

const StuderandeUsefulInformation = ({
  bookingLinks,
  usefulLinks,
  bodySlices,
  bookCarCalendarId,
  bookCarImage,
  bookCarLogoImage,
  bookCarText
}) => {
  const [pdfDialogContent, setPdfDialogContent] = useState()
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false)
  const t = useTranslation()
  const renderSlice = (slice, i) => {
    switch (slice.type) {
      case 'useful_links_pdf':
        return (
          <LinkWrapper
            key={RichText.asText(slice.primary.title)}
            onClick={() =>
              setPdfDialogContent({
                title: slice.primary.title,
                text: slice.primary.text,
                links: slice.fields
              })
            }
          >
            <LinkLabel>{RichText.asText(slice.primary.title)}</LinkLabel>
          </LinkWrapper>
        )
      default:
        return <h1 key={i}>SLICE TYPE: {slice.type} not found</h1>
    }
  }
  return (
    <>
      <Wrapper>
        <ContentWrapper>
          <Title>{t`Important documents`}</Title>
          {bodySlices.map((slice, i) => renderSlice(slice, i))}
        </ContentWrapper>
        <ContentWrapper>
          <Title>{t`Useful links`}</Title>
          {usefulLinks.map(link => (
            <LinkWrapper
              key={RichText.asText(link.title)}
              target='_blank'
              href={link.link ? link.link.url : '#'}
            >
              <LinkLabel>{RichText.asText(link.title)}</LinkLabel>
            </LinkWrapper>
          ))}
        </ContentWrapper>

        <ContentWrapper>
          <Title>{t`Book`}</Title>
          <LinkWrapper>
            <LinkLabel onClick={() => setBookingDialogOpen(true)}>
              Bil
            </LinkLabel>
          </LinkWrapper>
          {bookingLinks.map(link => (
            <LinkWrapper
              key={RichText.asText(link.booking_title)}
              target='_blank'
              href={link.booking_link ? link.booking_link.url : '#'}
            >
              <LinkLabel>{RichText.asText(link.booking_title)}</LinkLabel>
            </LinkWrapper>
          ))}
        </ContentWrapper>
      </Wrapper>
      <CarDialog
        open={bookingDialogOpen}
        close={() => setBookingDialogOpen(false)}
        bookCarImage={bookCarImage}
        bookCarCalendarId={bookCarCalendarId}
        bookCarLogoImage={bookCarLogoImage}
        bookCarText={bookCarText}
      />
      <PdfDialog
        content={pdfDialogContent}
        close={() => setPdfDialogContent()}
      />
    </>
  )
}

export default StuderandeUsefulInformation
