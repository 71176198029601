import React, { useContext } from 'react'
import { userContext } from '../../context/user-provider'
import useTranslation from '../../hooks/use-translation'
import Login from '../../views/login'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useContext(userContext)
  const t = useTranslation()
  if (!user) {
    return <Login />
  } else {
    return <Component {...rest} />
  }
}
export default PrivateRoute
