import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../../components/private-route'
import Studerande from '../studerande'
import { graphql } from 'gatsby'
import useLanguage from '../../hooks/use-language'
import { getNode, getNodes, getNodesTest } from '../../helpers/graph-helper'

const App = ({ data, pageContext }) => {
  console.log(pageContext)
  const language = useLanguage()
  let node = getNode(language.locale, data, 'allStudent_pages')
  let samo = getNode(language.locale, data, 'allSamos')
  let articles = getNodesTest(
    language.locale,
    pageContext.newsArticles
  )
  let informationArticles = getNodes(
    language.locale,
    data,
    'allStyret_information_articles'
  )

  return (
    <Router>
      <PrivateRoute
        path='/app/studerande'
        component={Studerande}
        node={node}
        informationArticles={informationArticles}
        articles={articles}
        samo={samo}
      ></PrivateRoute>
    </Router>
  )
}

export const query = graphql`
  {
    prismic {
      allStudent_pages {
        edges {
          node {
            _meta {
              lang
            }
            body {
              ... on PRISMIC_Student_pageBodyUseful_links_pdf {
                type
                label
                primary {
                  title
                  text
                }
                fields {
                  pdf_title
                  pdf_link {
                    _linkType
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                }
              }
            }
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
            schemes {
              scheme_title
              scheme_link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
            useful_links {
              title
              link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
           booking_links {
              booking_title
              booking_link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
            book_car_image
            book_car_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1200) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            book_car_logo_image
            book_car_logo_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 400) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            book_car_text
            book_car_calendar_id
          }
        }
      }
      allStyret_information_articles(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              lang
            }
            date
            text
            title
          }
        }
      }
      allSamos {
        edges {
          node {
            _meta {
              lang
            }
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1200) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            introduction
            long_text
            links {
              title1
              link {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default App
