import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import useOutsideClick from '../../../hooks/ous-outside-click'
import * as _ from 'lodash'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../../helpers/colors'
import useTranslation from '../../../hooks/use-translation'

const Wrapper = styled.div(
  ({ show, mobile }) => `
  position: absolute;
  z-index: 10;
  left: ${mobile ? '' : '40px'};
  right: ${mobile ? '40px' : ''};
  top: 40px;
  height: auto;
  width: ${mobile ? '200px' : '300px'};
  overflow: hidden;
  max-width: ${show ? 300 : 0}px;
  max-height: ${show ? 800 : 0}px;
  transition: all 0.3s ease-in-out;
  background-color: white;
  box-shadow: 1px 1px 8px 1px #000000cc;
`
)

const OptionsTitle = styled.h4`
  text-align: center;
  letter-spacing: 0px;
  color:${DEFAULT_GREY_TITLE};
  font-size: 20px;
  text-shadow: 0px 3px 6px #0000002b;
  padding: 10px;
`

const TypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
`
const Type = styled.div(
  ({ show }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 10px;
  margin: 15px;
  background-color: ${show ? DEFAULT_COLOR : 'grey'};
  border-radius: 3px;
  transition: all 0.1s ease-in-out;
  opacity: ${show ? 1 : 0.5};
  &:hover {
    cursor: pointer;
  }
  ::before {
    position: absolute;
    content: '';
    left: -13px;
    transform: rotate(45deg);
    width: 27px;
    height: 27px;
    background-color: ${show ? DEFAULT_COLOR : 'grey'};
    transition: all 0.1s ease-in-out;
  }
`
)

const TypeLabel = styled.p`
  z-index: 10;
  color: white;
`
const FeedOptions = ({
  allowedTypes,
  setAllowedTypes,
  articles,
  show,
  close,
  mobile
}) => {
  const [availableTypes, setAvailableTypes] = useState()
  const wrapperRef = useRef()
  const t = useTranslation()
  const toggle = type => {
    if (allowedTypes.includes(type)) {
      setAllowedTypes(allowedTypes.filter(x => x !== type))
    } else {
      setAllowedTypes([...allowedTypes, type])
    }
  }
  useEffect(() => {
    setAvailableTypes(_.sortedUniqBy(articles, 'type').map(x => x.type))
  }, [])
  useOutsideClick(wrapperRef, () => {
    show && close()
  })
  return (
    <Wrapper show={show} mobile={mobile} ref={wrapperRef}>
      <OptionsTitle>{t`Filter`}</OptionsTitle>
      <TypeWrapper>
        {availableTypes &&
          availableTypes.map((type, index) => (
            <Type
              key={index}
              show={allowedTypes.length === 0 || allowedTypes.includes(type)}
              onClick={() => toggle(type)}
            >
              <TypeLabel>{type}</TypeLabel>
            </Type>
          ))}
      </TypeWrapper>
    </Wrapper>
  )
}

export default FeedOptions
