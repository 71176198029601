import React from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers/media'
import InfoRow from '../../../components/info-row'
import Badge from '../../../components/badge'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import CustomRichText from '../../../components/custom-rich-text'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
  overflow-x: hidden;
  @media only screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LongTextWrapper = styled.div`
  padding: 60px;
  transition: font-size 0.3s ease-in-out;
  color: #4c4d4f;
  & p {
    color: rgba(0, 0, 0, 0.87);
    text-shadow: '';
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    margin-top: 10px;
    padding: 10px;
    & h2 {
      font-size: 18px;
      text-align: center;
    }
  }
`

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 40px;
`

const SamoDialog = ({ open, close, samo }) => {
  return (
    <Dialog
      open={!!open}
      onClose={close}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!open && (
        <Wrapper>
          <InnerWrapper>
            <InfoRow
              image={samo.hero_imageSharp.childImageSharp.fluid}
              title={samo.title}
              text={samo.introduction}
              maxHeight='500px'
              maxWidth='250px'
            />
            <LongTextWrapper>
              <CustomRichText richText={samo.long_text} />
            </LongTextWrapper>
            {samo.links && (
              <BadgeWrapper>
                {samo.links.map(link => (
                  <Badge
                    backgroundColor={DEFAULT_COLOR}
                    color='white'
                    width='auto'
                    target='_blank'
                    href={link.link.url}
                  >
                    {RichText.asText(link.title1)}
                  </Badge>
                ))}
              </BadgeWrapper>
            )}
          </InnerWrapper>
        </Wrapper>
      )}
    </Dialog>
  )
}

export default SamoDialog
