import React from 'react'
import styled from 'styled-components'
import Article from './article'
import moment from 'moment'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: relative;
  max-width: 850px;
  overflow-x: scroll;
`

const ArticleScroller = ({ handleClick, articles }) => {
  return (
    <Wrapper>
      {articles.map(
        (article, index) =>
          moment(article.date) <= moment() && (
            <Article
              key={index}
              handleClick={() => handleClick(article)}
              article={article}
            />
          )
      )}
      
    </Wrapper>
  )
}

export default ArticleScroller
