import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../../helpers/colors'
import { breakpoints } from '../../../helpers/media'
import moment from 'moment'
import useTranslation from '../../../hooks/use-translation'

const Wrapper = styled.div(
  () => `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 225px;
  min-width: 225px;
  height: 250px;
  margin: 15px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 3px -1px ${DEFAULT_COLOR};
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 150px;
  }
`
)

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    margin: 20px 5px 5px 5px;
  }
`

const ArticleTitle = styled.h4`
  color: ${DEFAULT_COLOR};
  font-size: 18px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`

const ArticleDate = styled.h5`
  position: absolute;
  top: -10px;
  right: -20px;
  color: ${DEFAULT_COLOR};
  font-size: 18px;
  background-color: ${DEFAULT_COLOR};
  color: white;
  border-radius: 50px;
  padding: 10px;
`

const ArticleTextWrapper = styled.div`
  height: 100px;
  overflow: hidden;
  color: ${DEFAULT_GREY_TITLE};
  font-size: 16px;
  margin-bottom: 25px;
  & img {
    display: none;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 12px;
  }
`

const ReadMore = styled.div`
  background-color: ${DEFAULT_COLOR};
  width: 120px;
  height: 40px;
  position: absolute;
  bottom: -15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 6px -1px #0000002e;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    bottom: -10px;
  }
`

const ReadMoreTitle = styled.h5`
  color: white;
`
const Article = ({ article, handleClick }) => {
  const t = useTranslation()
  const breadText = RichText.asText(article.text)
  return (
    <Wrapper onClick={() => handleClick()}>
      <ArticleHeader>
        <ArticleTitle>{RichText.asText(article.title)}</ArticleTitle>
        <ArticleDate>{moment(article.date).format('D/M')}</ArticleDate>
      </ArticleHeader>
      <ArticleTextWrapper>{breadText.slice(0,50)}{breadText.length > 50 ? '...' : ''}</ArticleTextWrapper>
      <ReadMore>
        <ReadMoreTitle>{t`Read more`}</ReadMoreTitle>
      </ReadMore>
    </Wrapper>
  )
}

export default Article
