import React from 'react'
import { Dialog } from '@material-ui/core'
import Img from 'gatsby-image'
import Slide from '@material-ui/core/Slide'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers/media'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../../helpers/colors'
import CarCalendar from './car-calendar'
import CustomRichText from '../../../components/custom-rich-text'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 200px;
  overflow-x: hidden;
`

const ImgWrapper = styled.div`
  max-height: 500px;
  width: 100%;
  overflow: hidden;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    max-height: 250px;
  }
`

const HeaderTextWrapper = styled.div`
  width: 500px;
  max-width: 85vw;
  padding: 20px;
  & p,
  a {
    margin-bottom: 20px;
    font-size: 22px;
    color: #f7f4f4;
    text-align: left;
    color: ${DEFAULT_GREY_TITLE};
    text-shadow: 0px 3px 6px #00000029;
    opacity: 0.93;
  }
  @media only screen and (max-width: ${breakpoints.lg}px) {
    padding: 20px;
    & p,
    a {
      text-align: center;
      color: black;
      font-size: 12px;
    }
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 60px;
  max-width: 100%;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
    padding: 10px;
  }
`

const Divider = styled.div`
  width: 2px;
  height: 250px;
  margin: 0px 60px 0px 60px;
  background-color: ${DEFAULT_COLOR};
  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: 250px;
    height: 2px;
  }
`

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.85);
`
const CarDialog = ({
  open,
  close,
  bookCarCalendarId,
  bookCarImage,
  bookCarLogoImage,
  bookCarText
}) => {
  return (
    <Dialog
      open={!!open}
      onClose={close}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!open && (
        <Wrapper>
          <ImgWrapper>
            <Img fluid={bookCarImage} />
          </ImgWrapper>
          <Flex>
            <Img
              fluid={bookCarLogoImage}
              style={{
                width: '200px',
                height: '200px',
                alignSelf: 'center',
                justifyContent: 'center'
              }}
            />
            <Divider />
            <HeaderTextWrapper>
              <CustomRichText richText={bookCarText} />
            </HeaderTextWrapper>
          </Flex>

          <CalendarWrapper>
            <CarCalendar
              calendarId={
                bookCarCalendarId
                  ? RichText.asText(bookCarCalendarId)
                  : 'rds0moakbgb85cp64fvhoi5m2g%40group.calendar.google.com'
              }
            />
          </CalendarWrapper>
        </Wrapper>
      )}
    </Dialog>
  )
}

export default CarDialog
