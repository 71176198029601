import React, { useContext } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { userContext } from '../context/user-provider'
import useTranslation from '../hooks/use-translation'
import { Button } from '@material-ui/core'
import { DEFAULT_COLOR } from '../helpers/colors'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ad5389; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
`

const Title = styled.h2`
  margin: 10px;
`

const Login = () => {
  const { initializeLogin } = useContext(userContext)
  const t = useTranslation()
  return (
    <Layout>
      <Wrapper>
        <div className='animate-in'>
          <Title>{t`You have to login to view this page`}!</Title>
          <Button
            variant='contained'
            onClick={async () => {
              const w = window.open('', '_blank')
              const link = await initializeLogin()
              if (link) {
                w.location = link
              }
            }}
            style={{ backgroundColor: DEFAULT_COLOR }}
          >
            <p style={{ color: 'white' }}>{t`Login`}</p>
          </Button>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default Login
