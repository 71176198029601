import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../../helpers/colors'
import { breakpoints } from '../../../helpers/media'
import moment from 'moment'

const Wrapper = styled.div(
  () => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40vw;
  border-radius: 10px;
  margin-right: 10px;
  transition: max-height 0.5s ease-in-out;
  box-shadow: 0px 0px 3px 1px #0000003d;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`
)

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 15px;
`

const TypeTitle = styled.h3`
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: ${DEFAULT_GREY_TITLE};
  opacity: 1;
`

const ImgWrapper = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 40vw;
  min-height: 100px;
  height: 100px;
  overflow: hidden;
`

const ArticleTitle = styled.h3`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: ${DEFAULT_COLOR};
  opacity: 1;
`

const MobileFeedArticle = ({ article, handleClick }) => {
  return (
    <Wrapper onClick={handleClick}>
      <ImgWrapper>
        {article.imageSharp && (
          <Img
            fluid={article.imageSharp.childImageSharp.fluid}
            style={{ height: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        )}
      </ImgWrapper>
      <ContentWrapper>
        <Row>
          <TypeTitle>{article.type}</TypeTitle>{' '}
          <TypeTitle style={{ textAlign: 'right' }}>
            {moment(article.date_start).format('D MMMM')}
          </TypeTitle>
        </Row>
        <ArticleTitle>{RichText.asText(article.title)}</ArticleTitle>
      </ContentWrapper>
    </Wrapper>
  )
}

export default MobileFeedArticle
