import React, { useState } from 'react'
import styled from 'styled-components'
import { DEFAULT_BACKGROUND } from '../../../helpers/colors'
import ArticleScroller from './article-scroller'
import ArticleDialog from './article-dialog'
import useTranslation from '../../../hooks/use-translation'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: ${DEFAULT_BACKGROUND};
  max-width: 100vw;
  overflow: hidden;
`

const ArticlesInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  overflow: hidden;
`
const Title = styled.h3`
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 40px;
  letter-spacing: 0px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  ::after {
    content: '';
    width: 40%;
    margin-left: 30%;
    height: 3px;
    background-color: white;
  }
`
const StuderandeImportantInformation = ({ articles }) => {
  const [selectedArticle, setSelectedArticle] = useState()
  const t = useTranslation()
  return (
    <>
      <Wrapper>
        <Title>{t`Important information`}</Title>
          <ArticlesInnerWrapper>
            <ArticleScroller
              handleClick={article => setSelectedArticle(article)}
              articles={articles}
            />
          </ArticlesInnerWrapper>
      </Wrapper>
      <ArticleDialog article={selectedArticle} close={() => setSelectedArticle()} />
    </>
  )
}

export default StuderandeImportantInformation
