import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import { subscribeUser, isSupported } from '../../../helpers/subscription'

const StuderandeNotificationDialog = () => {
  const [show, setShow] = useState(
    localStorage &&
      !localStorage.getItem('notifications') &&
      isSupported() &&
      Notification.permission !== 'granted'
  )
  return (
    <Dialog open={show}>
      <DialogTitle>Godkänn notifikationer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            <b>
              Nu har vi gjort det möjligt att skicka ut push-notifikationer till
              er!
            </b>
          </p>
          <p>
            Om ni godkänner detta så kommer ni få en notifikation varje gång det
            kommer ett nytt event eller information från styret!
          </p>
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={() => {
              localStorage.setItem('notifications', 0)
              setShow(false)
            }}
          >
            Nej tack
          </Button>
          <Button
            variant='contained'
            style={{ color: 'white', backgroundColor: DEFAULT_COLOR }}
            onClick={() => {
              setShow(false)
              Notification.requestPermission()
              subscribeUser()
            }}
          >
            Jag vill ha notifikationer
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default StuderandeNotificationDialog
