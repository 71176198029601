import React from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styled from 'styled-components'
import FeedArticle from '../feed-article'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
`

const ArticleDialog = ({ article, close }) => {
  return (
    <Dialog
      open={!!article}
      onClose={close}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!article && (
        <Wrapper>
          <FeedArticle showImage={false} article={article} />
        </Wrapper>
      )}
    </Dialog>
  )
}

export default ArticleDialog
