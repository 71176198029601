import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Icon, IconButton } from '@material-ui/core'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import FeedOptions from './feed-options'
import useTranslation from '../../../hooks/use-translation'
import MobileFeedArticle from './mobile-feed-article'

const FeedWrapper = styled.div`
  position: relative;
`

const FeedHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const FeedTitle = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: relative;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: ${DEFAULT_COLOR};
  ::after {
    content: '';
    width: 100px;
    height: 1px;
    background-color: ${DEFAULT_COLOR};
  }
`

const FeedOptionsWrapper = styled.div`
  margin-left: 15px;
  position: relative;
`

const FeedArticlesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding: 5px 0px 20px 0px;
`

const MobileFeed = ({ articles, handleClick }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [allowedTypes, setAllowedTypes] = useState([])
  const feedWrapperRef = useRef()
  const t = useTranslation()

  return (
    <FeedWrapper>
      <FeedHeader>
        <FeedTitle>{t`Newsfeed`}</FeedTitle>
        <FeedOptionsWrapper>
          <IconButton onClick={() => setShowOptions(true)}>
            <Icon fontSize='normal'>filter_list</Icon>
          </IconButton>
          <FeedOptions
            allowedTypes={allowedTypes}
            setAllowedTypes={setAllowedTypes}
            show={showOptions}
            close={() => setShowOptions(false)}
            articles={articles}
            mobile
          />
        </FeedOptionsWrapper>
      </FeedHeader>
      <FeedArticlesWrapper ref={feedWrapperRef}>
        {articles.map(
          article =>
            (allowedTypes.length === 0 ||
              allowedTypes.includes(article.type)) && (
              <MobileFeedArticle article={article} handleClick={() => handleClick(article)}/>
            )
        )}
      </FeedArticlesWrapper>
    </FeedWrapper>
  )
}

export default MobileFeed
