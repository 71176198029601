import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import FeedArticle from '../feed-article'
import { Icon, IconButton } from '@material-ui/core'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import FeedOptions from './feed-options'
import useTranslation from '../../../hooks/use-translation'
import { breakpoints } from '../../../helpers/media'

const FeedWrapper = styled.div`
  position: relative;
`

const FeedHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`
const FeedTitle = styled.h2`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  font-size: 40px;
  letter-spacing: 0px;
  color: ${DEFAULT_COLOR};
  text-shadow: 0px 3px 6px #0000002b;
  ::after {
    margin-top: 5px;
    content: '';
    width: 40%;
    margin-left: 30%;
    height: 3px;
    background-color: ${DEFAULT_COLOR};
  }
`

const FeedOptionsWrapper = styled.div`
  margin-left: 15px;
  position: relative;
`

const FeedArticlesWrapper = styled.div`
  width: 60vw;
  max-width: 1100px;
  max-height: 850px;
  overflow-y: scroll;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: 85vw;
  }
`

const ExpandWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: -10px;
  left: 20px;
  height: 50px;
  width: calc(100% - 20px);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    transparent 0%,
    rgba(195, 195, 195, 1) 100%
  );
`

const Feed = ({ articles }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [allowedTypes, setAllowedTypes] = useState([])
  const feedWrapperRef = useRef()
  const t = useTranslation()

  return (
    <FeedWrapper>
      <FeedHeader>
        <FeedTitle>{t`Newsfeed`}</FeedTitle>
        <FeedOptionsWrapper>
          <IconButton onClick={() => setShowOptions(true)}>
            <Icon fontSize='large'>filter_list</Icon>
          </IconButton>
          <FeedOptions
            allowedTypes={allowedTypes}
            setAllowedTypes={setAllowedTypes}
            show={showOptions}
            close={() => setShowOptions(false)}
            articles={articles}
          />
        </FeedOptionsWrapper>
      </FeedHeader>
      <FeedArticlesWrapper ref={feedWrapperRef}>
        {articles.map(
          (article, index) =>
            (allowedTypes.length === 0 ||
              allowedTypes.includes(article.type)) && (
              <FeedArticle key={index} article={article} />
            )
        )}
        {articles && articles.length > 2 && (
          <ExpandWrapper>
            <IconButton
              onMouseDown={() => {
                feedWrapperRef.current.scrollTop += 10
              }}
            >
              <Icon fontSize='large'>expand_more</Icon>
            </IconButton>
          </ExpandWrapper>
        )}
      </FeedArticlesWrapper>
    </FeedWrapper>
  )
}

export default Feed
