import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../helpers/colors'
import { breakpoints } from '../../helpers/media'
import Badge from '../../components/badge'
import moment from 'moment'
import ClickableImg from '../../components/clickable-img'
import CustomRichText from '../../components/custom-rich-text/index'

const Wrapper = styled.div(
  () => `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    ::after {
      content: '';
      height: 0px;
      margin: 30px 0px;
      width: 80%;
      background-color: ${DEFAULT_COLOR};
    }
  }
`
)

const NoImageLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  padding: 20px 40px 20px 20px;
  min-width: 300px;
  ::after {
    position: absolute;
    top: 0px;
    right: 0px;
    content: '';
    height: 75px;
    width: 3px;
    background-color: ${DEFAULT_COLOR};
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 150px;
    height: auto;
    padding: 5px;
    ::after {
      height: 0px;
    }
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 40px 20px 20px;
  min-width: 300px;
  height: 100%;
  ::after {
    position: absolute;
    right: 0px;
    top: 28px;
    content: '';
    height: calc(81%);
    width: 3px;
    background-color: ${DEFAULT_COLOR};
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 150px;
    height: auto;
    padding: 5px;
    ::after {
      height: 0px;
    }
  }
`

const TypeTitle = styled.h3`
  margin: 0px 0px 30px 0px;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0px;
  color: ${DEFAULT_GREY_TITLE};
  opacity: 1;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 22px;
    margin: 0px 0px 15px 0px;
  }
`

const ImgWrapper = styled.div`
  width: 250px;
  max-height: 150px;
  overflow: hidden;
`

const Right = styled.div(
  ({ showImage }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 40px 40px 40px;
  min-width: 400px;
  min-height: ${showImage && '250px'};
  inline-size: -webkit-fill-available;
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 200px;
    padding: 10px;
  }
`
)

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: center;
  font-size: 12px;
  & p {
    font-size: 10px;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    justify-content: flex-end;
    & p {
      font-size: 12px;
    }
  }
`

const ArticleTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0px 10px 0px;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: ${DEFAULT_COLOR};
  opacity: 1;
  @media only screen and (max-width: ${breakpoints.md}px) {
    & h4 {
      font-size: 14px;
      margin-bottom: 10px;
    }

    & h5 {
      font-size: 11px;
    }
    flex-wrap: wrap;
    margin: 0px;
    margin-bottom: 10px;
  }
`

const ArticleText = styled.div`
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: ${DEFAULT_GREY_TITLE};
  opacity: 0.93;
  & img {
    max-width: 400px;
    max-height: 400px;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 12px;
    & img {
      max-width: 250px;
    }
  }
`

const FeedArticle = ({ article, showImage = true }) => {
  const renderEndDate = () => {
    if (
      moment(article.date_start).format('D MMMM') ===
      moment(article.date_end).format('D MMMM')
    ) {
      return `-${' '}${moment(article.date_end).format('HH:mm')}`
    } else {
      return `-${' '}${moment(article.date_end).format('D MMMM HH:mm')}`
    }
  }
  return (
    <Wrapper>
      {showImage &&
        (article.imageSharp ? (
          <Left img={article.imageSharp}>
            <TypeTitle>{article.type}</TypeTitle>
            <ImgWrapper>
              <ClickableImg
                fluid={article.imageSharp.childImageSharp.fluid}
                style={{ height: '100%' }}
              />
            </ImgWrapper>
          </Left>
        ) : (
          <NoImageLeft>
            <TypeTitle>{article.type}</TypeTitle>
          </NoImageLeft>
        ))}
      <Right showImage={showImage}>
        <div>
          <ArticleTitle>
            <h4>{RichText.asText(article.title)}</h4>{' '}
            <h5 style={{ color: DEFAULT_GREY_TITLE }}>
              {moment(article.date_start).format('D MMMM HH:mm')}{' '}
              {article.date_end ? renderEndDate() : ''}
            </h5>
          </ArticleTitle>
          <ArticleText>
            <CustomRichText richText={article.text} />
          </ArticleText>
        </div>
        {article.links && article.links.length && (
          <BadgeWrapper>
            {article.links.map(link =>
              link.link_url ? (
                <Badge
                  marginTop='5px'
                  width='auto'
                  target='_blank'
                  href={link.link_url && link.link_url.url}
                >
                  <p>{link.link_title && RichText.asText(link.link_title)}</p>
                </Badge>
              ) : (
                ''
              )
            )}
          </BadgeWrapper>
        )}
      </Right>
    </Wrapper>
  )
}

export default FeedArticle
