import React, { useState } from 'react'
import styled from 'styled-components'
import InfoRow from '../../../components/info-row'
import SamoDialog from './samo-dialog'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 700px;
`
const StuderandeSamo = ({ samo }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Wrapper>
        <InfoRow
          image={samo.hero_imageSharp.childImageSharp.fluid}
          title={samo.title}
          text={samo.introduction}
          maxHeight='500px'
          action={{
            title: 'Read more',
            handleClick: () => setOpen(true)
          }}
        />
      </Wrapper>
      <SamoDialog open={open} close={() => setOpen(false)} samo={samo} />
    </>
  )
}

export default StuderandeSamo
