import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DEFAULT_COLOR, DEFAULT_GREY } from '../../helpers/colors'
import moment from 'moment'
import { breakpoints } from '../../helpers/media'
import { RichText } from 'prismic-reactjs'

const dayNames = ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön']
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  font-size: 12px;
  padding: 30px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 10px;
  }
`

const Head = styled.h5`
  color: ${DEFAULT_COLOR};
  text-align: center;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 10px;
  }
`

const Data = styled.div(
  ({ repetition, today, hasEvents, relevant, halfBooked }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  border: ${today ? `solid 2px ${DEFAULT_COLOR}` : ''};
  color: ${hasEvents ? 'white' : relevant ? 'black' : '#C0C0C0'};
  background-color: ${
    hasEvents ? (relevant ? DEFAULT_COLOR : '#C0C0C0') : 'transparent'
  };
  background: ${halfBooked &&
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAVklEQVQoU2OcF7LwPwMDA4NdvBOIQgGHFu6D8xlBCgkpAqlmvLP5MdhEZIBsEkwcQyE2RSAbURTiUoRiNT5FcIWEFIEVwoIH2TPYQgFDIa6gQlGILzwBh+o5GvgbP1QAAAAASUVORK5CYII=) repeat'};
  cursor: ${hasEvents ? 'pointer' : 'default'};
  @media only screen and (max-width: ${breakpoints.md}px) {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
`
)

const BadgeWrapper = styled.div(
  ({ repetition, relevant }) => `
  display: ${!!repetition ? 'none' : 'flex'};
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1000000;
  opacity: ${relevant ? 1 : 0.7};
`
)

const Badge = styled.span(
  ({ repetition }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0px 1px 16px 2px #00000054;
  color: ${DEFAULT_COLOR};
`
)
const HoverWrapper = styled.div`
  display: none;
  pointer-events: none;
  ${Data}:hover & {
    display: block;
  }
  position: fixed;
  @media only screen and (max-width: ${breakpoints.md}px) {
    width: 100vw;
    left: 0px;
    top: -50px;
  }
  z-index: 2000000;
`
const HoverInnerWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: -100px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0px 1px 16px 2px #00000054;
  width: 200px;
  background-color: white;
  z-index: 21000000;
  border-radius: 5px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    left: calc(50vw - 125px);
  }
`

const HoverRow = styled.div`
  color: ${DEFAULT_COLOR};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px ${DEFAULT_GREY};
  padding: 10px 1px;
`
const Days = ({
  dateKey = 'date_start',
  dateEndKey = 'date_end',
  date,
  articles,
  handleClick
}) => {
  const [days, setDays] = useState([])
  useEffect(() => {
    const relevantEvents = articles.filter(
      art => moment(art[dateKey]).month() === date.month() && moment(art[dateKey]).year() === date.year()
    )
    let _days = []
    const weekDay = date.isoWeekday() - 1
    for (let i = 0; i < weekDay; i++) {
      _days = [..._days, { empty: true }]
    }
    const realMonth = moment().month()
    const dateMonth = date.month()
    const _daysInMonth = date.daysInMonth()
    const dateNumber = parseInt(moment().format('D'))
    for (let i = 1; i <= _daysInMonth; i++) {
      let dayEvents = relevantEvents.filter(
        event => parseInt(moment(event[dateKey]).format('D')) === i
      )
      let hoursBooked = 0
      dayEvents.forEach(a => {
        hoursBooked =
          hoursBooked +
          moment
            .duration(moment(a.date_end).diff(a.date_start, 'hours'), 'hours')
            .asHours()
      })

      _days = [
        ..._days,
        {
          dayInMonth: i,
          hoursBooked,
          events: dayEvents,
          hasEvents: dayEvents.length,
          today: realMonth === dateMonth && i === dateNumber,
          relevant:
            (dateMonth === realMonth && i >= dateNumber) ||
            dateMonth > realMonth
        }
      ]
    }
    setDays(_days)
  }, [date])
  return (
    <Table>
      {dayNames.map(name => (
        <Head key={name} key={name}>
          {name}
        </Head>
      ))}
      {days &&
        days.map((day, index) => {
          let repetition =
            index !== 0 &&
            day.hasEvents &&
            days[index - 1].hasEvents &&
            day.events.length === 1 &&
            days[index - 1].events.length === 1 &&
            days[index - 1].events[0].title === day.events[0].title
          return (
            <Data
              repetition={repetition}
              key={index}
              today={day.today}
              hasEvents={day.hasEvents}
              relevant={day.relevant}
              halfBooked={!handleClick && day.hasEvents && day.relevant && day.hoursBooked < 23}
              onClick={() => {
                if (day.hasEvents && handleClick) {
                  handleClick(day)
                }
              }}
            >
              {day.empty ? '' : <p style={{ zIndex: 10 }}>{day.dayInMonth}</p>}
              {day.hasEvents && day.events.length > 1 ? (
                <BadgeWrapper repetition={repetition} relevant={day.relevant}>
                  <Badge>{day.events.length}</Badge>
                </BadgeWrapper>
              ) : (
                ''
              )}
              {!handleClick && day.hasEvents ? (
                <HoverWrapper>
                  <HoverInnerWrapper>
                    {day.events.map(event => {
                      return (
                        <>
                          <HoverRow>
                            <div
                              style={{
                                justifySelf: 'flexStart',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {moment(event.date_start).format('HH:mm')}{' '}
                              {event.date_end
                                ? ` -  ${moment(event.date_end).format(
                                    'HH:mm'
                                  )}`
                                : ''}
                            </div>
                            {typeof event.title === 'string'
                              ? event.title
                              : event.title ? RichText.asText(event.title) : ''}
                          </HoverRow>
                        </>
                      )
                    })}
                  </HoverInnerWrapper>
                </HoverWrapper>
              ) : (
                ''
              )}
            </Data>
          )
        })}
    </Table>
  )
}

export default Days
